<template>
  <div>
    <h1 class="text-3xl font-semibold mb-4">Privacy Policy</h1>
    <p class="mb-4">At Penpoint Consulting, we take your privacy seriously. This policy outlines how we collect, use, and protect your personal information.</p>
    <h2 class="text-2xl font-semibold mt-6 mb-2">Information Collection</h2>
    <p class="mb-4">We collect information that you provide directly to us, such as when you create an account, request a consultation, or contact our support team.</p>
    <h2 class="text-2xl font-semibold mt-6 mb-2">Use of Information</h2>
    <p class="mb-4">We use the information we collect to provide, maintain, and improve our services, as well as to communicate with you about our products and services.</p>
    <h2 class="text-2xl font-semibold mt-6 mb-2">Data Protection</h2>
    <p class="mb-4">We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>
  </div>
</template>