<template>
  <div class="flex flex-col min-h-screen" :class="{ 'dark': isDarkMode }">
    <header class="bg-blue-950 dark:bg-blue-950 shadow-sm">
      <nav class="container mx-auto px-4 py-4 flex justify-between items-center">
        <router-link to="/" class="text-xl font-semibold text-white">Penpoint Consulting</router-link>
        <div class="flex items-center space-x-4">
          <button @click="toggleDarkMode" class="text-gray-300 hover:text-white transition-colors duration-200">
            <font-awesome-icon :icon="isDarkMode ? 'sun' : 'moon'" />
          </button>
          <router-link v-for="route in routes" :key="route.path" :to="route.path" class="text-gray-300 hover:text-white transition-colors duration-200">
            {{ route.name }}
          </router-link>
        </div>
      </nav>
    </header>

    <main class="flex-grow container mx-auto px-4 py-8">
      <router-view></router-view>
    </main>

    <footer class="bg-blue-950 shadow-sm">
      <div class="container mx-auto px-4 py-4 text-center text-white">
        © {{ new Date().getFullYear() }} Penpoint Consulting. All rights reserved.
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { RouterLink, RouterView } from 'vue-router'

const routes = ref([
  { path: '/careers', name: 'Careers' },
  { path: '/support', name: 'Support' },
  { path: '/privacy', name: 'Privacy' },
  { path: '/about', name: 'About' },
  { path: '/contact', name: 'Contact' },
])

const isDarkMode = ref(false)

const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value
  updateDarkMode()
}

const updateDarkMode = () => {
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

onMounted(() => {
  isDarkMode.value = window.matchMedia('(prefers-color-scheme: dark)').matches
  updateDarkMode()

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    isDarkMode.value = e.matches
    updateDarkMode()
  })
})
</script>