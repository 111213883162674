<template>
  <div class="max-w-6xl mx-auto flex flex-col lg:flex-row items-start">

    <div class="lg:w-2/3 lg:pr-8">
      <h1 class="text-3xl font-semibold mb-6">About Penpoint Consulting</h1>
    
      <p class="mb-6">Penpoint Consulting is a woman-owned small business delivering innovative IT solutions to both public and private sector organizations. We support civilian and defense customers within the public sector, leveraging our expertise and cutting-edge technologies to help organizations optimize their operations and achieve sustainable growth in the digital landscape.</p>
      
      <h2 class="text-2xl font-semibold mt-8 mb-4">Our Services</h2>
      <ul class="list-disc list-inside mb-6">
        <li>IT Consulting Services</li>
        <li>Staff Augmentation</li>
        <li>Software Engineering</li>
        <li>Custom Software Development</li>
        <li>Information Security Solutions</li>
      </ul>
      
      <h2 class="text-2xl font-semibold mt-8 mb-4">Our Commitment</h2>
      <p class="mb-6">As a woman-owned small business, we are committed to fostering diversity and innovation in the tech industry. We take pride in delivering high-quality solutions that meet the unique needs of both civilian and defense agencies, ensuring their success in an ever-evolving digital world.</p>
      
      <h2 class="text-2xl font-semibold mt-8 mb-4">Why Choose Penpoint Consulting?</h2>
      <ul class="list-disc list-inside mb-6">
        <li>Expertise in both civilian and defense sectors</li>
        <li>Tailored solutions to meet specific agency needs</li>
        <li>Commitment to innovation and cutting-edge technologies</li>
        <li>Strong focus on information security</li>
        <li>Diverse and experienced team of professionals</li>
      </ul>
      
      <p class="mt-8">At Penpoint Consulting, we're not just service providers – we're your partners in digital transformation. Let us help you navigate the complexities of modern IT landscapes and unlock your organization's full potential.</p>
    </div>
    <div class="lg:w-1/3 mt-8 lg:mt-0">
      <img src="@/assets/about.webp" alt="About Penpoint Consulting" class="w-full h-auto rounded-lg shadow-lg">
    </div>
  </div>
</template>