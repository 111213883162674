<template>
  <div>
    <h1 class="text-4xl font-semibold mb-4">Contact Us</h1>
    <p>We'd love to hear from you. Please use the form below to get in touch with us.</p>

    <form class="mt-6 max-w-lg">
      <div class="mb-4">
        <label for="name" class="block font-medium text-gray-700 dark:text-gray-300">Name</label>
        <input type="text" id="name" name="name" class="mt-1 block w-full rounded-md bg-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>
      <div class="mb-4">
        <label for="email" class="block font-medium text-gray-700 dark:text-gray-300">Email</label>
        <input type="email" id="email" name="email" class="mt-1 block w-full rounded-md bg-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>
      <div class="mb-4">
        <label for="message" class="block font-medium text-gray-700 dark:text-gray-300">Message</label>
        <textarea id="message" name="message" rows="4" class="mt-1 block w-full rounded-md bg-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
      </div>
      <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Send Message
      </button>
    </form>
  </div>
</template>
