<template>
  <div>
    <h1 class="text-4xl font-semibold mb-4">Support</h1>
    <p>Welcome to the Support Page. Our goal is to ensure that your experience with our app is seamless, enjoyable, and meets all your needs. If you have questions, need assistance, or would like to report a problem, we're here to help.</p>

    <h2 class="text-3xl font-semibold mt-4 mb-2">Frequently Asked Questions (FAQs)</h2>
    <p>Before reaching out, please take a moment to browse our FAQs. Many common questions and issues are addressed here, providing you with the quickest solutions to your needs.</p>
    <a href="#" class="btn btn-primary">Link to FAQs</a>

    <h2 class="text-3xl font-semibold mt-4 mb-2">Contact Us</h2>
    <p>If you can't find the answers you're looking for in our FAQs, our dedicated support team is available to assist you. Here's how you can reach us:</p>

    <h3 class="text-2xl font-semibold mt-4 mb-2">Email Support</h3>
    <p>Send us an email at <a href="mailto:support@penpoint.dev">support@penpoint.dev</a>, and we'll get back to you as soon as possible, typically within 24-48 hours.</p>

    <h3 class="text-2xl font-semibold mt-4 mb-2">Live Chat</h3>
    <p>For immediate assistance, you can use our live chat feature available on our website during business hours <strong>9:00A-5:00P Eastern time Monday - Friday</strong>.</p>

    <h3 class="text-2xl font-semibold mt-4 mb-2">Social Media</h3>
    <p>You can also reach out to us through our social media channels:</p>
    <ul>
        <li>Twitter: <a href="https://twitter.com/#">Coming Soon...</a></li>
        <li>Facebook: <a href="https://facebook.com/#">Coming Soon...</a></li>
    </ul>

    <h2 class="text-3xl font-semibold mt-4 mb-2">Report a Bug</h2>
    <p>Encountered a bug? Let us know so we can fix it as quickly as possible. Please provide as much detail about the issue as you can, including screenshots if possible, and email it to <a href="mailto:support@penpoint.dev">support@penpoint.dev</a>.</p>

    <h2 class="text-3xl font-semibold mt-4 mb-2">Feature Requests</h2>
    <p>We love hearing how we can make our apps even better. If you have ideas for new features or improvements, please email us at <a href="mailto:support@penpoint.dev">support@penpoint.dev</a>.</p>

    <h2 class="text-3xl font-semibold mt-4 mb-2">More Information</h2>
    <p>For more information about our apps, including tutorials, tips, and news, visit our website: <a href="https://penpoint.dev">penpoint.dev</a>.</p>
  </div>
</template>