import { createRouter, createWebHistory } from 'vue-router'
import HomeView from './views/HomeView.vue'
import CareersView from './views/CareersView.vue'
import SupportView from './views/SupportView.vue'
import PrivacyView from './views/PrivacyView.vue'
import AboutView from './views/AboutView.vue'
import ContactView from './views/ContactView.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/careers', component: CareersView },
  { path: '/support', component: SupportView },
  { path: '/privacy', component: PrivacyView },
  { path: '/about', component: AboutView },
  { path: '/contact', component: ContactView },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router